import React, { useContext } from 'react';
import Router from 'next/router';
import SideBar from '../Sidebar';
import './topbar.css';
import AuthGlobal from '../../context/store/global';
import { logoutUser } from '../../context/actions';
import { Avatar, Popover, Menu, Position, LogOutIcon, PersonIcon, CaretDownIcon } from 'evergreen-ui';

const TopBar = () => {
    const { dispatch, globalState: { user, isAuthenticated } } = useContext(AuthGlobal);

    return (
        <>
            <div className='navbar-bg'></div>
            <nav className='navbar main-navbar' style={{ zIndex: 1 }}>
                <form className='form-inline mr-auto d-xl-none'>
                    <ul className='navbar-nav mr-3'>
                        <li>
                            <a data-toggle='sidebar' className='nav-link nav-link-lg text-white'>
                                <i className='fas fa-bars'></i>
                            </a>
                        </li>
                    </ul>
                    <div className='search-element'></div>
                </form>
                <ul className='navbar-nav navbar-right ml-auto'>
                    {isAuthenticated ? (
                        <Popover
                            position={Position.BOTTOM_RIGHT}
                            content={
                                <Menu>
                                    <Menu.Group>
                                        <Menu.Item icon={PersonIcon} onSelect={() => Router.push('/profile')}>
                                            My Profile
                                        </Menu.Item>
                                    </Menu.Group>
                                    <Menu.Divider />
                                    <Menu.Group>
                                        <Menu.Item 
                                            icon={LogOutIcon} 
                                            intent='danger' 
                                            onSelect={() => {
                                                logoutUser(dispatch);
                                                Router.push('/');
                                            }}
                                        >
                                            Logout
                                        </Menu.Item>
                                    </Menu.Group>
                                </Menu>
                            }
                        >
                            <div className='avatar-container' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingRight: '25px' }}>
                                <Avatar
                                    src={user.photo ?? '/images/profile-bone.jpg'}
                                    name={`${user.first_name} ${user.last_name}`}
                                    size={40}
                                />
                                <span className='user-greeting' style={{ marginLeft: '10px', color: '#fff', display: 'flex', alignItems: 'center' }}>
        Hi, {user.first_name} {user.last_name}
                                    <CaretDownIcon style={{ marginLeft: '8px', color: '#fff' }} />
                                </span>
                            </div>


                        </Popover>
                    ) : (
                        <li>
                            <a href='/login' className='nav-link nav-link-user'>
                                <div>Login</div>
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
            <SideBar />
        </>
    );
};

export default TopBar;
