import React, { useEffect, useState, useContext } from 'react'
import Router, { useRouter } from 'next/router'
import AuthGlobal from '../../context/store/global'
import TopBar from '../TopBar'
import Footer from '../Footer'
import './index.css'
import Zoom from 'react-reveal/Zoom'
import Jump from 'react-reveal/Jump'
import ProgressBar from '../Progress/ProgressBar'
import { getScoreboardMetrics } from '../../utils/scoreboards-helper'
import Accordion from './accoridion'
import { quarter1Data, quarter2Data, quarter3Data, quarter4Data } from './accordionData'
import DonutChart from '../donutChart'
import Scoreboard from '../Scoreboard'
import GenericScore from '../Scores/GenericScore'
import moment from 'moment-timezone'
import {
    GlobeIcon,
    GraphIcon,
    ShieldIcon,
    StarIcon,
    Heading,
    Pane,
    FlagIcon,
    HeartIcon,
    LightbulbIcon,
    EndorsedIcon,
    TickCircleIcon,
    WrenchIcon,
    PieChartIcon,
    CaretDownIcon,
    CaretUpIcon,
    Text,
    Image,
    IconButton
} from 'evergreen-ui'

const Dashboard = () => {
    const {
        globalState: { isAuthenticated }
    } = useContext(AuthGlobal)
    //GeneralMetrics it is equal to Total Compliace
    const [currentTotalCompliance, setCurrentTotalCompliance] = useState({})

    const [currentObjectives, setCurrentObjectives] = useState({})

    const [currentGeneralActivities, setCurrentGeneralActivities] = useState({})

    const [revenue, setRevenue] = useState('')
    const [profit, setProfit] = useState('')

    const [totalScores, setTotalScores] = useState(0)
    const [loading, setLoading] = useState(true)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (!isAuthenticated) {
            Router.push(`/login`)
        }
    }, [isAuthenticated])

    useEffect(() => {
        async function getAllMetrics() {
            const { success, response, error } = await getScoreboardMetrics()
            if (success && response) {
                setCurrentGeneralActivities(response.generalActivities)
                setCurrentObjectives(response.rocks)
                setCurrentTotalCompliance(response.totalCompliance)
                setRevenue(response.revenue)
                setProfit(response.profit)
                setTotalScores(10)
                setLoading(false)
            }
        }
        getAllMetrics()
    }, [])

    const getTotalMetrics = scores => {
        const total =
            scores.lowCriticalNumber + scores.mediumCriticalNumber + scores.criticalNumber + scores.highCriticalNumber
        return total
    }

    return (
        <div className='main-wraper'>
            <TopBar />
            <div className='main-content'>
                <section className='section'>
                    <Pane className='section-header' display='flex' alignItems='center' borderRadius={8}>
                        <GlobeIcon size={32} color='#1070CA' marginRight={8} />
                        <Heading size={800}>ClickIT Culture</Heading>
                    </Pane>
                    <div className='section-body'>
                        <div className='row equal dashboard-page'>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8} overflow='hidden'>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            padding={16}
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            id='headingCoreValues'
                                        >
                                            <Pane display='flex' alignItems='center'>
                                                <HeartIcon size={32} color='#EC4C47' marginRight={8} />
                                                <Heading size={600} color='white'>
                                                    Core Values
                                                </Heading>
                                            </Pane>
                                            <IconButton
                                                icon={
                                                    isOpen ? (
                                                        <CaretUpIcon color='white' />
                                                    ) : (
                                                        <CaretDownIcon color='white' />
                                                    )
                                                }
                                                cursor='pointer'
                                                appearance='minimal'
                                                intent='none'
                                                onClick={() => setIsOpen(!isOpen)}
                                            />
                                        </Pane>

                                        {/* Conditionally render the content when isOpen is true */}
                                        {isOpen && (
                                            <Pane padding={16} height={400} background='#fff'>
                                                <Pane display='flex' alignItems='center' marginBottom={12}>
                                                    <Image
                                                        src='images/core-values/Collaboration.png'
                                                        width={50}
                                                        height={50}
                                                        marginRight={8}
                                                    />
                                                    <Text>
                                                        <b>Collaboration:</b> Collaboration breeds innovation.
                                                    </Text>
                                                </Pane>
                                                <Pane display='flex' alignItems='center' marginBottom={12}>
                                                    <Image
                                                        src='images/core-values/Commitment.png'
                                                        width={50}
                                                        height={50}
                                                        marginRight={8}
                                                    />
                                                    <Text>
                                                        <b>Commitment:</b> Commitment propels us towards success.
                                                    </Text>
                                                </Pane>
                                                <Pane display='flex' alignItems='center' marginBottom={12}>
                                                    <Image
                                                        src='images/core-values/Evolution.png'
                                                        width={50}
                                                        height={50}
                                                        marginRight={8}
                                                    />
                                                    <Text>
                                                        <b>Evolution:</b> Never stop evolving; keep on learning.
                                                    </Text>
                                                </Pane>
                                                <Pane display='flex' alignItems='center' marginBottom={12}>
                                                    <Image
                                                        src='images/core-values/Diversity.png'
                                                        width={50}
                                                        height={50}
                                                        marginRight={8}
                                                    />
                                                    <Text>
                                                        <b>Diversity:</b> Embracing diversity, fostering unity.
                                                    </Text>
                                                </Pane>
                                                <Pane display='flex' alignItems='center'>
                                                    <Image
                                                        src='images/core-values/Heartitude.png'
                                                        width={50}
                                                        height={50}
                                                        marginRight={8}
                                                    />
                                                    <Text>
                                                        <b>Heartitude:</b> Heartitude shines through every action.
                                                    </Text>
                                                </Pane>
                                            </Pane>
                                        )}
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8} overflow='hidden'>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            padding={16}
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            id='headingPurpose'
                                        >
                                            <Pane display='flex' alignItems='center'>
                                                <LightbulbIcon size={32} color='#F7D154' marginRight={8} />
                                                <Heading size={600} color='white'>
                                                    Purpose
                                                </Heading>
                                            </Pane>
                                            <IconButton
                                                icon={
                                                    isOpen ? (
                                                        <CaretUpIcon color='white' />
                                                    ) : (
                                                        <CaretDownIcon color='white' />
                                                    )
                                                }
                                                cursor='pointer'
                                                appearance='minimal'
                                                intent='none'
                                                onClick={() => setIsOpen(!isOpen)}
                                            />
                                        </Pane>

                                        {/* Conditionally render the content when isOpen is true */}
                                        {isOpen && (
                                            <Pane padding={16} height={400} background='#fff'>
                                                <Text style={{ textAlign: 'justify' }}>
                                                    <i
                                                        className='fas fa-star'
                                                        style={{ color: '#FFC107', paddingRight: '8px' }}
                                                    ></i>
                                                    Our purpose is to transform the lives of both our clients and
                                                    collaborators. We achieve this by providing our clients with quality
                                                    solutions that exceed their expectations, which in turn allows us to
                                                    provide an ideal workplace and conditions for every employee at
                                                    ClickIT, ensuring their professional, personal, and economic
                                                    development.
                                                </Text>
                                            </Pane>
                                        )}
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8} overflow='hidden'>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            padding={16}
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            id='headingBrandPromises'
                                        >
                                            <Pane display='flex' alignItems='center'>
                                                <ShieldIcon size={32} color='#1070CA' marginRight={8} />
                                                <Heading size={600} color='white'>
                                                    Brand Promises
                                                </Heading>
                                            </Pane>
                                            <IconButton
                                                icon={
                                                    isOpen ? (
                                                        <CaretUpIcon color='white' />
                                                    ) : (
                                                        <CaretDownIcon color='white' />
                                                    )
                                                }
                                                cursor='pointer'
                                                appearance='minimal'
                                                intent='none'
                                                onClick={() => setIsOpen(!isOpen)}
                                            />
                                        </Pane>

                                        {/* Conditionally render the content when isOpen is true */}
                                        {isOpen && (
                                            <Pane padding={16} height={400} background='#fff'>
                                                <Pane>
                                                    <StarIcon color="#FFC107" marginRight={8} />
                                                    <Text>Your goals become ours</Text>
                                                </Pane>
                                                <Pane>
                                                    <StarIcon color="#FFC107" marginRight={8} />
                                                    <Text>Lean and agile support at the right time</Text>
                                                </Pane>
                                                <Pane>
                                                    <StarIcon color="#FFC107" marginRight={8} />
                                                    <Text>Cost-effective solutions</Text>
                                                </Pane>
                                            </Pane>
                                        )}
                                    </Pane>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                    {revenue === '' || profit === '' ? (
                        ''
                    ) : (
                        <div>
                            <Pane className='section-header' display='flex' alignItems='center'>
                                <GraphIcon size={32} color='#425A70' marginRight={8} />
                                <Heading size={800}>Critical Numbers</Heading>
                            </Pane>
                            <div className='row equal dashboard-page'>
                                <div className='col col-12 col-md-12 col-xl-6'>
                                    <GenericScore scoreboard={revenue}></GenericScore>
                                </div>
                                <div className='col col-12 col-md-12 col-xl-6'>
                                    <GenericScore scoreboard={profit}></GenericScore>
                                </div>
                            </div>
                        </div>
                    )}
                    <Pane className='section-header' display='flex' alignItems='center'>
                        <ShieldIcon size={32} color='#1070CA' marginRight={8} />
                        <Heading size={800}>Compliance Levels</Heading>
                    </Pane>
                    <div className='row equal dashboard-page'>
                        <div className='col col-12 col-md-12 col-xl-4'>
                            <Zoom>
                                <Pane border='default' borderRadius={8}>
                                    {/* Card Header */}
                                    <Pane
                                        background='#054aaf'
                                        borderTopLeftRadius={8}
                                        borderTopRightRadius={8}
                                        padding={16}
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <WrenchIcon size={32} color='#D9822B' marginRight={8} />
                                        <Pane>
                                            <Heading size={600} color='white'>
                                                General Activities
                                            </Heading>
                                            <Text size={400} color='white'>
                                                Compliance:{' '}
                                                {(
                                                    ((currentGeneralActivities.highCriticalNumber +
                                                        currentGeneralActivities.criticalNumber) /
                                                        getTotalMetrics(currentGeneralActivities)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </Text>
                                        </Pane>
                                    </Pane>

                                    {/* Card Body */}
                                    <Pane
                                        padding={16}
                                        borderBottomLeftRadius={8}
                                        borderBottomRightRadius={8}
                                        background='#fff'
                                    >
                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'green' }}>SuperGreen</label>
                                            <ProgressBar
                                                bgcolor='green'
                                                showed={currentGeneralActivities.highCriticalNumber}
                                                completed={
                                                    (currentGeneralActivities.highCriticalNumber * 100) /
                                                    getTotalMetrics(currentGeneralActivities)
                                                }
                                                total={getTotalMetrics(currentGeneralActivities)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'rgb(58, 208, 34)' }}>Green</label>
                                            <ProgressBar
                                                bgcolor='rgb(58, 208, 34)'
                                                showed={currentGeneralActivities.criticalNumber}
                                                completed={
                                                    (currentGeneralActivities.criticalNumber * 100) /
                                                    getTotalMetrics(currentGeneralActivities)
                                                }
                                                total={getTotalMetrics(currentGeneralActivities)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: '#FFC312' }}>Yellow</label>
                                            <ProgressBar
                                                bgcolor='#FFC312'
                                                showed={currentGeneralActivities.mediumCriticalNumber}
                                                completed={
                                                    (currentGeneralActivities.mediumCriticalNumber * 100) /
                                                    getTotalMetrics(currentGeneralActivities)
                                                }
                                                total={getTotalMetrics(currentGeneralActivities)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'red' }}>Red</label>
                                            <ProgressBar
                                                bgcolor='red'
                                                showed={currentGeneralActivities.lowCriticalNumber}
                                                completed={
                                                    (currentGeneralActivities.lowCriticalNumber * 100) /
                                                    getTotalMetrics(currentGeneralActivities)
                                                }
                                                total={getTotalMetrics(currentGeneralActivities)}
                                            />
                                        </Pane>
                                    </Pane>

                                    {/* Donut Chart */}
                                    {!loading && (
                                        <DonutChart
                                            superGreen={currentGeneralActivities.highCriticalNumber}
                                            green={currentGeneralActivities.criticalNumber}
                                            yellow={currentGeneralActivities.mediumCriticalNumber}
                                            red={currentGeneralActivities.lowCriticalNumber}
                                            style={{ marginBottom: '15px', display: 'none' }}
                                        />
                                    )}
                                </Pane>
                            </Zoom>
                        </div>
                        <div className='col col-12 col-md-12 col-xl-4'>
                            <Zoom>
                                <Pane border='default' borderRadius={8}>
                                    {/* Card Header */}
                                    <Pane
                                        background='dark'
                                        borderTopLeftRadius={8}
                                        borderTopRightRadius={8}
                                        padding={16}
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <EndorsedIcon size={32} color='#D9822B' marginRight={8} />
                                        <Pane>
                                            <Heading size={600} color='white'>
                                                Objectives
                                            </Heading>
                                            <Text size={400} color='white'>
                                                Compliance:{' '}
                                                {(
                                                    ((currentObjectives.highCriticalNumber +
                                                        currentObjectives.criticalNumber) /
                                                        getTotalMetrics(currentObjectives)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </Text>
                                        </Pane>
                                    </Pane>

                                    {/* Card Body */}
                                    <Pane
                                        padding={16}
                                        borderBottomLeftRadius={8}
                                        borderBottomRightRadius={8}
                                        background='white'
                                    >
                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'green' }}>SuperGreen</label>
                                            <ProgressBar
                                                bgcolor='green'
                                                showed={currentObjectives.highCriticalNumber}
                                                completed={
                                                    (currentObjectives.highCriticalNumber * 100) /
                                                    getTotalMetrics(currentObjectives)
                                                }
                                                total={getTotalMetrics(currentObjectives)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'rgb(58, 208, 34)' }}>Green</label>
                                            <ProgressBar
                                                bgcolor='rgb(58, 208, 34)'
                                                showed={currentObjectives.criticalNumber}
                                                completed={
                                                    (currentObjectives.criticalNumber * 100) /
                                                    getTotalMetrics(currentObjectives)
                                                }
                                                total={getTotalMetrics(currentObjectives)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: '#FFC312' }}>Yellow</label>
                                            <ProgressBar
                                                bgcolor='#FFC312'
                                                showed={currentObjectives.mediumCriticalNumber}
                                                completed={
                                                    (currentObjectives.mediumCriticalNumber * 100) /
                                                    getTotalMetrics(currentObjectives)
                                                }
                                                total={getTotalMetrics(currentObjectives)}
                                            />
                                        </Pane>

                                        <Pane marginBottom={8}>
                                            <label style={{ color: 'red' }}>Red</label>
                                            <ProgressBar
                                                bgcolor='red'
                                                showed={currentObjectives.lowCriticalNumber}
                                                completed={
                                                    (currentObjectives.lowCriticalNumber * 100) /
                                                    getTotalMetrics(currentObjectives)
                                                }
                                                total={getTotalMetrics(currentObjectives)}
                                            />
                                        </Pane>
                                    </Pane>

                                    {/* Donut Chart */}
                                    {!loading && (
                                        <DonutChart
                                            superGreen={currentObjectives.highCriticalNumber}
                                            green={currentObjectives.criticalNumber}
                                            yellow={currentObjectives.mediumCriticalNumber}
                                            red={currentObjectives.lowCriticalNumber}
                                            style={{ marginBottom: '15px', display: 'none' }}
                                        />
                                    )}
                                </Pane>
                            </Zoom>
                        </div>
                        <div className='col col-12 col-md-12 col-xl-4'>
                            <Zoom>
                                <Pane border='default' borderRadius={8}>
                                    {/* Card Header */}
                                    <Pane
                                        background='dark'
                                        borderTopLeftRadius={8}
                                        borderTopRightRadius={8}
                                        padding={16}
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <TickCircleIcon size={32} color='#47B881' marginRight={8} />
                                        <Pane>
                                            <Heading size={600} color='white'>
                                                Total Compliance
                                            </Heading>
                                            <Text size={400} color='white'>
                                                Compliance:
                                                {(
                                                    ((currentGeneralActivities.highCriticalNumber +
                                                        currentGeneralActivities.criticalNumber) /
                                                        getTotalMetrics(currentGeneralActivities)) *
                                                    100
                                                ).toFixed(2)}
                                            </Text>
                                        </Pane>
                                    </Pane>

                                    {/* Card Body */}
                                    <Pane
                                        padding={16}
                                        borderBottomLeftRadius={8}
                                        borderBottomRightRadius={8}
                                        background='white'
                                    >
                                        <form>
                                            <Pane marginBottom={8}>
                                                <label style={{ color: 'green' }}>SuperGreen</label>
                                                <ProgressBar
                                                    bgcolor='green'
                                                    showed={currentTotalCompliance.highCriticalNumber}
                                                    completed={
                                                        (currentTotalCompliance.highCriticalNumber * 100) /
                                                        getTotalMetrics(currentTotalCompliance)
                                                    }
                                                    total={getTotalMetrics(currentTotalCompliance)}
                                                />
                                            </Pane>

                                            <Pane marginBottom={8}>
                                                <label style={{ color: 'rgb(58, 208, 34)' }}>Green</label>
                                                <ProgressBar
                                                    bgcolor='rgb(58, 208, 34)'
                                                    showed={currentTotalCompliance.criticalNumber}
                                                    completed={
                                                        (currentTotalCompliance.criticalNumber * 100) /
                                                        getTotalMetrics(currentTotalCompliance)
                                                    }
                                                    total={getTotalMetrics(currentTotalCompliance)}
                                                />
                                            </Pane>

                                            <Pane marginBottom={8}>
                                                <label style={{ color: '#FFC312' }}>Yellow</label>
                                                <ProgressBar
                                                    bgcolor='#FFC312'
                                                    showed={currentTotalCompliance.mediumCriticalNumber}
                                                    completed={
                                                        (currentTotalCompliance.mediumCriticalNumber * 100) /
                                                        getTotalMetrics(currentTotalCompliance)
                                                    }
                                                    total={getTotalMetrics(currentTotalCompliance)}
                                                />
                                            </Pane>

                                            <Pane marginBottom={8}>
                                                <label style={{ color: 'red' }}>Red</label>
                                                <ProgressBar
                                                    bgcolor='red'
                                                    showed={currentTotalCompliance.lowCriticalNumber}
                                                    completed={
                                                        (currentTotalCompliance.lowCriticalNumber * 100) /
                                                        getTotalMetrics(currentTotalCompliance)
                                                    }
                                                    total={getTotalMetrics(currentTotalCompliance)}
                                                />
                                            </Pane>
                                        </form>
                                    </Pane>

                                    {/* Donut Chart */}
                                    {!loading && (
                                        <DonutChart
                                            superGreen={currentTotalCompliance.highCriticalNumber}
                                            green={currentTotalCompliance.criticalNumber}
                                            yellow={currentTotalCompliance.mediumCriticalNumber}
                                            red={currentTotalCompliance.lowCriticalNumber}
                                            style={{ marginBottom: '15px', display: 'none' }}
                                        />
                                    )}
                                </Pane>
                            </Zoom>
                        </div>
                    </div>
                    <Zoom>
                        <Pane className='section-header' display='flex' alignItems='center'>
                            <StarIcon size={32} color='#FFC107' marginRight={8} />
                            <Heading size={800}>Strategic Priorities</Heading>
                        </Pane>
                    </Zoom>
                    <div className='section-body'>
                        <div className='row'>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane id="accordionFirstQuarter" borderRadius={8} elevation={2} marginBottom={16}>
                                        <Pane background="dark" padding={16} borderRadius={8}>
                                            <a
                                                data-toggle="collapse"
                                                style={{ width: '100%', textDecoration: 'none' }}
                                                data-target="#collapseOne"
                                                aria-expanded="false"
                                                aria-controls="collapseOne"
                                            >
                                                <Heading
                                                    size={600}
                                                    display="flex"
                                                    alignItems="center"
                                                    color="white"
                                                    fontSize="20px"
                                                >
                                                    <PieChartIcon size={32} color="#47B881" marginRight={8} />
                                                    First Quarter
                                                    <CaretDownIcon marginLeft="auto" />                                                </Heading>
                                            </a>
                                        </Pane>

                                        <Pane
                                            id="collapseOne"
                                            className="collapse"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionFirstQuarter"
                                        >
                                            <Pane padding={16} background="tint2" borderBottomLeftRadius={8} borderBottomRightRadius={8}>
                                                <Pane id="firstQuarterAccordion">
                                                    {quarter1Data.map((firstQItem, index) => (
                                                        <Accordion
                                                            title={firstQItem.title}
                                                            data={firstQItem.data}
                                                            keyId={index}
                                                            key={index}
                                                            parentId="firstQuarterAccordion"
                                                        />
                                                    ))}
                                                </Pane>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane id="accordionSecondQuarter" borderRadius={8} elevation={2} marginBottom={16}>
                                        <Pane background="dark" padding={16} borderRadius={8}>
                                            <a
                                                data-toggle="collapse"
                                                style={{ width: '100%', textDecoration: 'none' }}
                                                data-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                <Heading
                                                    size={600}
                                                    display="flex"
                                                    alignItems="center"
                                                    color="white"
                                                    fontSize="20px"
                                                >
                                                    <PieChartIcon size={32} color="#47B881" marginRight={8} />
                                                    Second Quarter
                                                    <CaretDownIcon marginLeft="auto" />                                                  </Heading>
                                            </a>
                                        </Pane>

                                        <Pane
                                            id="collapseTwo"
                                            className="collapse"
                                            aria-labelledby="headingTwo"
                                            data-parent="#accordionSecondQuarter"
                                        >
                                            <Pane padding={16} background="tint2" borderBottomLeftRadius={8} borderBottomRightRadius={8}>
                                                <Pane id="secondQuarterAccordion">
                                                    {quarter2Data.map((secondQItem, index) => (
                                                        <Accordion
                                                            title={secondQItem.title}
                                                            data={secondQItem.data}
                                                            keyId={index}
                                                            key={index}
                                                            parentId="secondQuarterAccordion"
                                                        />
                                                    ))}
                                                </Pane>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane id="accordionThirdQuarter" borderRadius={8} elevation={2} marginBottom={16}>
                                        <Pane background="dark" padding={16} borderRadius={8}>
                                            <a
                                                data-toggle="collapse"
                                                style={{ width: '100%', textDecoration: 'none' }}
                                                data-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                <Heading
                                                    size={600}
                                                    display="flex"
                                                    alignItems="center"
                                                    color="white"
                                                    fontSize="20px"
                                                >
                                                    <PieChartIcon size={32} color="#47B881" marginRight={8} />
                                                    Third Quarter
                                                    <CaretDownIcon marginLeft="auto" />                                                  </Heading>
                                            </a>
                                        </Pane>

                                        <Pane
                                            id="collapseThree"
                                            className="collapse"
                                            aria-labelledby="headingThree"
                                            data-parent="#accordionThirdQuarter"

                                        >
                                            <Pane padding={16} background="tint2" borderBottomLeftRadius={8} borderBottomRightRadius={8}>
                                                <Pane id="thirdQuarterAccordion">
                                                    {quarter3Data.map((thirdQItem, index) => (
                                                        <Accordion
                                                            title={thirdQItem.title}
                                                            data={thirdQItem.data}
                                                            keyId={index}
                                                            key={index}
                                                            parentId="thirdQuarterAccordion"
                                                        />
                                                    ))}
                                                </Pane>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane id="accordionFourthQuarter" borderRadius={8} elevation={2} marginBottom={16}>
                                        <Pane background="dark" padding={16} borderRadius={8}>
                                            <a
                                                data-toggle="collapse"
                                                style={{ width: '100%', textDecoration: 'none' }}
                                                data-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour"
                                            >
                                                <Heading
                                                    size={600}
                                                    display="flex"
                                                    alignItems="center"
                                                    color="white"
                                                    fontSize="20px"
                                                >
                                                    <PieChartIcon size={32} color="#47B881" marginRight={8} />
                                                    Fourth Quarter
                                                    <CaretDownIcon marginLeft="auto" />                                                  </Heading>
                                            </a>
                                        </Pane>

                                        <Pane
                                            id="collapseFour"
                                            className="collapse"
                                            aria-labelledby="headingFour"
                                            data-parent="#accordionFourthQuarter"
                                        >
                                            <Pane padding={16} background="tint2" borderBottomLeftRadius={8} borderBottomRightRadius={8}>
                                                <Pane id="fourthQuarterAccordion">
                                                    {quarter4Data.map((fourthQItem, index) => (
                                                        <Accordion
                                                            title={fourthQItem.title}
                                                            data={fourthQItem.data}
                                                            keyId={index}
                                                            key={index}
                                                            parentId="fourthQuarterAccordion"
                                                        />
                                                    ))}
                                                </Pane>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                        </div>
                        <Pane className='section-header' display='flex' alignItems='center'>
                            <FlagIcon size={32} color='#47B881' marginRight={8} />
                            <Heading size={800}>Goals</Heading>
                        </Pane>
                        <div className='row equal dashboard-page'>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8}>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            borderTopLeftRadius={8}
                                            borderTopRightRadius={8}
                                            padding={16}
                                        >
                                            <Heading size={600} color='white' display='flex' alignItems='center'>
                                                <PieChartIcon size={32} color='#47B881' marginRight={8} />
                                                Quarter Goal
                                            </Heading>
                                        </Pane>

                                        {/* Card Body */}
                                        <Pane
                                            padding={32}
                                            display='flex'
                                            alignItems='center'
                                            borderBottomLeftRadius={8}
                                            borderBottomRightRadius={8}
                                            background='#fff'
                                        >
                                            <Text>
                                                <StarIcon color='#FFC107' marginRight={8} />
                                                By the end of March 2024, the total revenue of the quarter must be a
                                                profit of 8%, revenue of 8%, and operational cost savings of 3%. The
                                                priorities are more sales and creating more opportunities in the sales
                                                pipeline.
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8}>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            borderTopLeftRadius={8}
                                            borderTopRightRadius={8}
                                            padding={16}
                                        >
                                            <Heading size={600} color='white' display='flex' alignItems='center'>
                                                <PieChartIcon size={32} color='#47B881' marginRight={8} />
                                                Current Year Goal
                                            </Heading>
                                        </Pane>

                                        {/* Card Body */}
                                        <Pane
                                            padding={32}
                                            display='flex'
                                            alignItems='center'
                                            borderBottomLeftRadius={8}
                                            borderBottomRightRadius={8}
                                            background='#fff'
                                        >
                                            <Text>
                                                <StarIcon color='#FFC107' marginRight={8} />
                                                By the end of 2024, the company&#39;s growth should be +40% compared to
                                                the previous year, with an average profit of 8.7% per quarter, focusing
                                                on more sales and attention to the customer satisfaction process.
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8}>
                                        {/* Card Header */}
                                        <Pane
                                            background='dark'
                                            padding={16}
                                            borderTopLeftRadius={8}
                                            borderTopRightRadius={8}
                                        >
                                            <Heading size={600} color='white' display='flex' alignItems='center'>
                                                <PieChartIcon size={32} color='#47B881' marginRight={8} />
                                                Three - Five Years
                                            </Heading>
                                        </Pane>

                                        {/* Card Body */}
                                        <Pane
                                            background='white'
                                            padding={42}
                                            display='flex'
                                            alignItems='center'
                                            borderBottomLeftRadius={8}
                                            borderBottomRightRadius={8}
                                        >
                                            <Text>
                                                <StarIcon color='#FFC107' marginRight={8} />
                                                By the year 2026, the total revenue must be:{' '}
                                                <strong>$4.5 Million</strong> USD, and have over <strong>100</strong>{' '}
                                                collaborators, <strong>80</strong> engineers in operations, and{' '}
                                                <strong>20</strong> in administration.
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <Pane border='default' borderRadius={8}>
                                        {/* Card Header */}
                                        <Pane background='dark' padding={16} borderTopLeftRadius={8} borderTopRightRadius={8}>
                                            <Heading size={600} color='white' display='flex' alignItems='center'>
                                                <PieChartIcon size={32} color='#47B881' marginRight={8} />
                                                BHAG - 10 years
                                            </Heading>
                                        </Pane>

                                        {/* Card Body */}
                                        <Pane
                                            background='white'
                                            padding={32}
                                            display='flex'
                                            alignItems='center'
                                            borderBottomLeftRadius={8}
                                            borderBottomRightRadius={8}
                                        >
                                            <Text>
                                                <StarIcon color='#FFC107' marginRight={8} />
                                                In the year 2031, ClickIT DevOps & Software Development is located in
                                                the top 5 of the Mexican IT agencies with wide international
                                                recognition, and is made up of more than 600 employees.
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Dashboard
