import React, { useState } from 'react'
import { Doughnut, Line, HorizontalBar, Bar } from 'react-chartjs-2'
import ProgressBar from '../graphics'
import InverseGraph from '../graphics/InverseGraph'
import './scoreboard.css'
import { EditIcon } from 'evergreen-ui'
import moment from 'moment'

// eslint-disable-next-line react/prop-types
const Scoreboard = ({ data, canEdit = false, setPullData }) => {
    const [showEditModal, setShowEditModal] = useState(false)
    //posicion del grafico en la tarjeta
    return (
        <div
            className='container column'
            style={{
                width: '100%',
                height: '20%',
                position: 'absolute',
                right: '-5px',
                minWidth: '100%',
                minHeight: '20%'
            }}
        >
            {/*eslint-disable-next-line react/jsx-no-undef*/}
            {showEditModal && <ModalEdit setShowEditModal={setShowEditModal} data={data} setPullData={setPullData} />}

            <div className='graph'>
                {
                    {
                        Doughnut: <Doughnut data={data.graphData} />,
                        Line: <Line data={data.graphData} />,
                        Bar: <Bar data={data.graphData} />,
                        'Horizontal Bar': <HorizontalBar data={data.graphData} />,
                        'Progress Bar V1': !data.inverse ? (
                            <ProgressBar
                                v={1}
                                data={data.graphData}
                                highCriticalNumber={data.highCriticalNumber}
                                criticalNumber={data.criticalNumber}
                                mediumCriticalNumber={data.mediumCriticalNumber}
                                lowCriticalNumber={data.lowCriticalNumber}
                                currentScore={data.currentScore}
                                unity={data.unity}
                                quarter={data.quarter}
                                rock={data.rock}
                                canEdit={canEdit}
                                slug={data.slug}
                                //assignedPhoto={data.assignedTo.photo || '/images/avatar-1.png'}
                                deadlineDates={moment(data.deadlineDates).format('YYYY/MM/DD')}
                            ></ProgressBar>
                        ) : (
                            <InverseGraph
                                v={1}
                                data={data.graphData}
                                highCriticalNumber={data.highCriticalNumber}
                                criticalNumber={data.criticalNumber}
                                mediumCriticalNumber={data.mediumCriticalNumber}
                                lowCriticalNumber={data.lowCriticalNumber}
                                currentScore={data.currentScore}
                                unity={data.unity}
                                quarter={data.quarter}
                                rock={data.rock}
                                canEdit={canEdit}
                                slug={data.slug}
                                //assignedPhoto={data.assignedTo.photo || '/images/avatar-1.png'}
                                deadlineDates={moment(data.deadlineDates).format('YYYY/MM/DD')}
                            ></InverseGraph>
                        ),
                        'Progress Bar V2': (
                            <ProgressBar
                                v={2}
                                data={data.graphData}
                                unity={data.unity}
                                highCriticalNumber={data.highCriticalNumber}
                                criticalNumber={data.criticalNumber}
                                mediumCriticalNumber={data.mediumCriticalNumber}
                                lowCriticalNumber={data.lowCriticalNumber}
                                currentScore={data.currentScore}
                                canEdit={canEdit}
                                slug={data.slug}
                                //assignedPhoto={data.assignedTo.photo || '/images/avatar-2.png'}
                                deadlineDates={moment(data.deadlineDates).format('YYYY/MM/DD')}
                            />
                        )
                    }[data.graphType]
                }
            </div>
        </div>
    )
}

export default Scoreboard
