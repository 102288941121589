import React, { useState, useContext } from 'react'
import Scoreboard from '../Scoreboard'
import moment from 'moment'
import _ from 'lodash'
import { priorityScoreboard } from '../../utils/scoreboards-helper'
import scoresUpdate from './scoresUpdate.css'
import AuthGlobal from '../../context/store/global'
import Router from 'next/router'
import { Pane, Heading, IconButton, Text, Strong, Tooltip, FlagIcon } from 'evergreen-ui'

const GenericScore = ({ scoreboard }) => {
    let _month = new Date().getMonth()
    let _quarter = Math.floor((_month + 3) / 3)

    // get both dates with .getTime() in miliseconds, then apply a conversion to get hours
    //Subtraction of the real-time date, minus scoreboard.updated date
    var msDiff = new Date().getTime() - new Date(scoreboard.updated).getTime()
    var daysTillneeded = Math.floor(msDiff / (1000 * 60 * 60 * 24)) // days
    //default date of creation
    var createdHours = new Date().getTime() - new Date(scoreboard.created).getTime()
    var daysSinceCreation = Math.floor(createdHours / (1000 * 60 * 60 * 24)) //days

    //message on the pop-up, all wrapped
    var quarter = _.get(scoreboard, 'quarter', moment(scoreboard.deadlineDates).subtract(10, 'days').calendar())
    var created = moment.utc(scoreboard.created).format('LL')
    var qc = 'Quarter: ' + quarter + ', Created at: ' + created
    var lastUpdate = scoreboard.updated //if updated property its undefined, then use creation date
    if (lastUpdate === undefined) {
        lastUpdate = moment(scoreboard.created).fromNow()
    } else lastUpdate = moment(scoreboard.updated).fromNow()
    var lastModif = 'Last Modification: ' + lastUpdate
    var completedOn = 'Completed on: ' + moment.utc(scoreboard.updated).format('LL')

    if (_quarter !== quarter) {
        var humanDeadlineDate = moment(scoreboard.deadlineDates)
        var updatedDate = moment(scoreboard.updated)
        if (updatedDate === undefined) {
            updatedDate = moment(scoreboard.created)
        } else if (scoreboard.currentScore !== 0) {
            updatedDate = moment(scoreboard.updated)
        } else {
            updatedDate = moment(scoreboard.updated)
        }
        var timeLeft = humanDeadlineDate.diff(updatedDate)
        var daysTillneeded = Math.abs(Math.floor(timeLeft / (1000 * 60 * 60 * 24)))
        if (daysTillneeded >= 1 && daysTillneeded < 2) {
            var lastModif = 'Last Modification: ' + daysTillneeded + ' day ago before deadline date'
        } else {
            lastModif = 'Last Modification: ' + daysTillneeded + ' days ago before deadline date'
        }
        if (daysTillneeded >= 30 && daysTillneeded <= 62) {
            lastModif = 'Last Modification: A month ago before deadline date'
        }
        if (daysTillneeded > 62) {
            var numberOfMonths = Math.floor(daysTillneeded / 30)
            lastModif = `Last Modification: ${numberOfMonths} months ago before deadline date`
        }
    }

    const oneWeek = () => {
        // Binomial functions that dictate the colors, according with the last modification date
        if (
            (daysTillneeded <= 7 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (daysTillneeded <= 7 &&
                scoreboard.currentScore >= scoreboard.highCriticalNumber &&
                scoreboard.inverse !== false) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation <= 7 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber &&
                scoreboard.inverse === false) ||
            (scoreboard.updated === undefined && daysSinceCreation <= 7 && scoreboard.inverse === true)
        ) {
            return true
        }
        return false
    }

    const twoWeeks = () => {
        if (
            (daysTillneeded > 7 &&
                daysTillneeded < 15 &&
                scoreboard.currentScore > scoreboard.highCriticalNumber &&
                scoreboard.inverse !== false) ||
            (daysTillneeded > 7 &&
                daysTillneeded < 15 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber &&
                scoreboard.inverse === false) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation > 7 &&
                daysSinceCreation < 15 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (daysSinceCreation > 7 &&
                daysSinceCreation < 15 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore <= scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const threeWeeks = () => {
        if (
            (daysTillneeded >= 15 && daysTillneeded < 22 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation >= 15 &&
                daysSinceCreation < 22 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.currentScore === 0 &&
                daysSinceCreation >= 15 &&
                daysSinceCreation < 22 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore < scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const fourWeeks = () => {
        if (
            (daysTillneeded >= 22 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation >= 22 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.currentScore === 0 &&
                daysSinceCreation >= 22 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore < scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const scoreboardsCompleted = () => {
        if (scoreboard.currentScore >= scoreboard.highCriticalNumber && scoreboard.inverse === false) {
            return true
        }
        return false
    }

    return (
        <Pane borderRadius={8} width='100%' elevation={1} backgroundColor='#ffffff'>
            {/* Card Header */}
            <Pane
                background={scoreboard.priority ? '#FA9800' : '#054aaf'}
                padding={16}
                borderTopLeftRadius={8}
                borderTopRightRadius={8}
            >
                <Heading size={600} color='white'>
                    {scoreboard.name}
                </Heading>
            </Pane>

            {/* Card Body */}
            <Pane padding={16}>
                <Pane paddingBottom={16}>
                    <Text size={500} style={{ wordWrap: 'break-word', fontSize: '18px' }}>
                        {scoreboard.description}
                    </Text>
                </Pane>

                {/* Scoreboard Component */}
                <Pane minHeight={120}>
                    <Scoreboard data={scoreboard} />
                </Pane>

                {/* Deadline Section */}
                <Pane display='flex' justifyContent='space-between' alignItems='center' marginTop={16}>
                    <Pane>
                        <Strong>Deadline:</Strong>{' '}
                        <Text color='blue'>{moment.utc(scoreboard.deadlineDates).format('LL')}</Text>
                    </Pane>

                    {/* Flags */}
                    <Pane>
                        {oneWeek() && (
                            <Tooltip content={lastModif}>
                                <IconButton size={25} paddingRight={8} icon={FlagIcon} iconColor='#006400' appearance='minimal' />
                            </Tooltip>
                        )}
                        {twoWeeks() && (
                            <Tooltip content={lastModif}>
                                <IconButton size={25} paddingRight={8} icon={FlagIcon} iconColor='#3AD022' appearance='minimal' />
                            </Tooltip>
                        )}
                        {threeWeeks() && (
                            <Tooltip content={lastModif}>
                                <IconButton size={25} paddingRight={8} icon={FlagIcon} iconColor='#FFC312' appearance='minimal' />
                            </Tooltip>
                        )}
                        {fourWeeks() && (
                            <Tooltip content={lastModif}>
                                <IconButton size={25} paddingRight={8} icon={FlagIcon} iconColor='red' appearance='minimal' />
                            </Tooltip>
                        )}
                        {scoreboardsCompleted() && (
                            <Tooltip content={completedOn}>
                                <IconButton size={25} paddingRight={8} icon={FlagIcon} iconColor='blue' appearance='minimal' />
                            </Tooltip>
                        )}
                    </Pane>
                </Pane>
            </Pane>
        </Pane>
    )
}

export default GenericScore
