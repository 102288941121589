import React from 'react'
import { Pane, Heading, Icon, StarIcon, CaretDownIcon, TickIcon, Text } from 'evergreen-ui';

const Accordion = ({ keyId, title, parentId, data, open }) => {
    return (
        <Pane
            border
            borderRadius={4}
            padding={12}
            background="tint1"
            marginBottom={16}
        >
            <Heading size={500} id={`heading${keyId}-${parentId}`}>
                <a
                    className="d-flex"
                    style={{ width: '100%' }}
                    data-toggle="collapse"
                    data-target={`#collapse${keyId}-${parentId}`}
                    aria-expanded={open ? 'open' : 'false'}
                    aria-controls={`collapse${keyId}-${parentId}`}
                >
                    <Icon icon={StarIcon} color="#FFC107" marginRight={8} />
                    {title}
                    <Icon icon={CaretDownIcon} color="black" className="ml-auto" />
                </a>
            </Heading>
            <Pane
                id={`collapse${keyId}-${parentId}`}
                className="collapse"
                aria-labelledby={`heading${keyId}-${parentId}`}
                data-parent={`#${parentId}`}
            >
                <Pane paddingTop={12}>
                    {data.map((paraph, index) => (
                        <Pane key={index} display="flex" alignItems="center" marginBottom={8}>
                            <Icon icon={TickIcon} color="#FFC107" marginRight={8} />
                            <Text>{paraph}</Text>
                        </Pane>
                    ))}
                </Pane>
            </Pane>
        </Pane>
    )
}

export default Accordion
